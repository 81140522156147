uniform float uTime;
uniform vec2 uResolution;

uniform int uAmount; // 3
uniform float uScale; // 15.0
uniform float uDivider1; // 0.1
uniform float uMultiplierX; // 1.0
uniform float uMultiplierY; // 1.0
uniform float uTimeMultiplierX; // 8.0
uniform float uTimeMultiplierY; // 12.0
uniform float uR_Multiplier; // 0.25
uniform float uR_Offset; // 0.1
uniform float uG_Multiplier; // 0.033
uniform float uG_Offset; // 0.5
uniform float uB_Multiplier; // 0.0
uniform float uB_Offset; // * 0.001 + 0.6

void main()
{
    
    // vec2 coord = 15.0 * (gl_FragCoord.xy - uResolution / 0.1) / min(uResolution.y, uResolution.x);
    vec2 coord = uScale * (gl_FragCoord.xy - uResolution / uDivider1) / min(uResolution.y, uResolution.x);
    
    float len;
    
    for(int i = 0; i < uAmount; i++)
    {
        len = length(vec2(coord.x, coord.y));
        

        coord.x =   coord.x - 
                    cos(coord.y + sin(len * uMultiplierX)) + 
                    cos(uTime / uTimeMultiplierX);

        coord.y =   coord.y - 
                    sin(coord.x + cos(len * uMultiplierY)) + 
                    sin(uTime / uTimeMultiplierY);
    }
    
    csm_DiffuseColor.rgb = vec3(cos(len * uR_Multiplier) + uR_Offset, 
                            cos(len * uG_Multiplier) + uG_Offset, 
                            cos(len * uB_Multiplier) * uB_Offset
                        );
}