import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'
import { mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js'
import CustomShaderMaterial from 'three-custom-shader-material/vanilla'
import Stats from 'stats.js'
import GUI from 'lil-gui'

import testVertexShader from './shaders/tests/vertex.glsl'
import testFragmentShader from './shaders/tests/fragment.glsl'
import overlayVertexShader from './shaders/overlay/vertex.glsl'
import overlayFragmentShader from './shaders/overlay/fragment.glsl'
import wobbleVertexShader from './shaders/wobble/vertex.glsl'
import wobbleFragmentShader from './shaders/wobble/fragment.glsl'

// Post-Processing
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { DotScreenPass } from 'three/examples/jsm/postprocessing/DotScreenPass.js'
// import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
// import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js'
import { GammaCorrectionShader } from 'three/examples/jsm/shaders/GammaCorrectionShader.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'


// console.log(mergeVertices)

// LOADERS
// ----------------------------------------------------------------------------------
// 
const loadingBarElement = document.querySelector('.loading-bar')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {

        // Method #1
        gsap.delayedCall(0.5, () => {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0 })
            // loadingBarElement.style.transformorigin = 'top right',
            // loadingBarElement.style.transform = 'scaleX(0)'
            // console.log('loaded')
            loadingBarElement.style.transform = ''
            loadingBarElement.classList.add('ended')
        })
    },
    // In Progress
    (itemUrl, itemsLoaded, itemsTotal) => {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
        console.log(itemUrl, Math.ceil(itemsLoaded / itemsTotal * 100), '%')
    }
)

// Loaders
const rgbeLoader = new RGBELoader()
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

// // DRACO
// const dracoLoader = new DRACOLoader()
// dracoLoader.setDecoderPath('./draco/')

// // GLTF
// const gltfLoader = new GLTFLoader(loadingManager)
// gltfLoader.setDRACOLoader(dracoLoader)

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)


// BASE
// ----------------------------------------------------------------------------------
// 

// Stats
const stats = new Stats()
stats.showPanel(0)
// document.body.appendChild(stats.dom)

// Debug
const gui = new GUI({ width: 450 })
gui.hide()
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Overlay
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: overlayVertexShader,
    fragmentShader: overlayFragmentShader
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

// Update All Materials
// --------------------
const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            // child.material.envMap = environmentMap
            child.material = testShaderMaterial
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}




// ENVIRONMENT MAP
// ----------------------------------------------------------------------------------
// 
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/0/px.jpg',
    '/textures/environmentMaps/0/nx.jpg',
    '/textures/environmentMaps/0/py.jpg',
    '/textures/environmentMaps/0/ny.jpg',
    '/textures/environmentMaps/0/pz.jpg',
    '/textures/environmentMaps/0/nz.jpg'
])

environmentMap.colorSpace = THREE.SRGBColorSpace

scene.background = environmentMap
scene.environment = environmentMap

debugObject.envMapIntensity = 2.5

// // RGBE LOADER EXAMPLE
// rgbeLoader.load('./urban_alley_01_1k.hdr', (environmentMap) => {
//     environmentMap.mapping = THREE.EquirectangularReflectionMapping

//     scene.background = environmentMap
//     scene.environment = environmentMap
// })


// // WOBBLE MATERIAL
// // ----------------------------------------------------------------------------------
// // 
// debugObject.colorA = '#ff00bb'
// debugObject.colorB = '#ffc800'


// const uniforms = {
//     uTime: new THREE.Uniform(0),
//     uPositionFrequency: new THREE.Uniform(0.5),
//     uTimeFrequency: new THREE.Uniform(0.4),
//     uStrength: new THREE.Uniform(0.1),

//     uWarpedPositionFrequency: new THREE.Uniform(0),
//     uWarpedTimeFrequency: new THREE.Uniform(0),
//     uWarpedStrength: new THREE.Uniform(0),

//     uColorA: new THREE.Uniform(new THREE.Color(debugObject.colorA)),
//     uColorB: new THREE.Uniform(new THREE.Color(debugObject.colorB))
// }

// // Material
// const wobbleMaterial = new CustomShaderMaterial({
//     //  CSM
//     baseMaterial: THREE.MeshPhysicalMaterial,
//     vertexShader: wobbleVertexShader,
//     fragmentShader: wobbleFragmentShader,
//     uniforms: uniforms,
//     silent: true,

//     // MeshPhysicalMaterial
//     metalness: 1,
//     roughness: 0.2,
//     // color: '#ffffff',
//     transmission: 0.5,
//     ior: 1.132,
//     thickness: 6.518,
//     transparent: true,
//     wireframe: true
// })

// // // New DepthMaterial
// // const depthMaterial = new CustomShaderMaterial({
// //     //  CSM
// //     baseMaterial: THREE.MeshDepthMaterial,
// //     vertexShader: wobbleVertexShader,
// //     // fragmentShader: wobbleFragmentShader,
// //     uniforms: uniforms,
// //     silent: true,

// //     // MeshDepthMaterial
// //     depthPacking: THREE.RGBADepthPacking

// // })

// // Tweaks
// gui.add(uniforms.uPositionFrequency, 'value', 0, 2, 0.001).name('uPositionFrequency')
// gui.add(uniforms.uTimeFrequency, 'value', 0, 2, 0.001).name('uTimeFrequency')
// gui.add(uniforms.uStrength, 'value', 0, 2, 0.001).name('uStrength')

// gui.add(uniforms.uWarpedPositionFrequency, 'value', 0, 2, 0.001).name('uWarpedPositionFrequency')
// gui.add(uniforms.uWarpedTimeFrequency, 'value', 0, 2, 0.001).name('uWarpedTimeFrequency')
// gui.add(uniforms.uWarpedStrength, 'value', 0, 2, 0.001).name('uWarpedStrength')

// gui
//     .addColor(debugObject, 'colorA')
//     .onChange(() => {
//         uniforms.uColorA.value.set(debugObject.colorA)
//     })
// gui
//     .addColor(debugObject, 'colorB')
//     .onChange(() => {
//         uniforms.uColorB.value.set(debugObject.colorB)
//     })

// gui.add(wobbleMaterial, 'metalness', 0, 1, 0.001)
// gui.add(wobbleMaterial, 'roughness', 0, 1, 0.001)
// gui.add(wobbleMaterial, 'transmission', 0, 1, 0.001)
// gui.add(wobbleMaterial, 'ior', 0, 10, 0.001)
// gui.add(wobbleMaterial, 'thickness', 0, 10, 0.001)





// MODELS
// ----------------------------------------------------------------------------------
// 
let logoModel = null

gltfLoader.load(
    './models/RafLogo/glTF/RafDesignLogo.glb',
    (gltf) => {
        gltf.scene.scale.set(1.6, 1.6, 1.6)
        gltf.scene.position.set(0, 0, 0)
        gltf.scene.rotation.y = Math.PI
        gltf.scene.rotation.x = - Math.PI * 0.5
        gltf.scene.rotation.z = Math.PI * 0.25
        scene.add(gltf.scene)

        gltf.scene.traverse(() => {

        })

        updateAllMaterials()
    },
    () => {
        console.log('in progress')
    },
    () => {
        console.log('error')
    }
)





// LIGHTS
// ----------------------------------------------------------------------------------
//
const directionalLight = new THREE.DirectionalLight('#ffffff', 4)
directionalLight.castShadow = true
directionalLight.shadow.camera.far = 15
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.normalBias = 0.05
directionalLight.position.set(0.25, 3, - 2.25)
scene.add(directionalLight)





// SIZES
// ----------------------------------------------------------------------------------
//
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


// SHADER MATERIAL
// ----------------------------------------------------------------------------------
// 

// // Material
// const wobbleMaterial = new CustomShaderMaterial({
//     //  CSM
//     baseMaterial: THREE.MeshPhysicalMaterial,
//     vertexShader: wobbleVertexShader,
//     fragmentShader: wobbleFragmentShader,
//     uniforms: uniforms,
//     silent: true,

//     // MeshPhysicalMaterial
//     metalness: 1,
//     roughness: 0.2,
//     // color: '#ffffff',
//     transmission: 0.5,
//     ior: 1.132,
//     thickness: 6.518,
//     transparent: true,
//     wireframe: true
// })



const testShaderMaterial = new CustomShaderMaterial({
    baseMaterial: THREE.MeshPhysicalMaterial,
    vertexShader: testVertexShader,
    fragmentShader: testFragmentShader,
    silent: true,
    uniforms:
    {
        uTime: new THREE.Uniform(0),
        uResolution: new THREE.Uniform(new THREE.Vector2(sizes.width, sizes.height)),

        // Parameters
        uAmount: new THREE.Uniform(23),
        uScale: new THREE.Uniform(11.15),
        uDivider1: new THREE.Uniform(1.51),
        uMultiplierX: new THREE.Uniform(-0.93),
        uMultiplierY: new THREE.Uniform(-3.68),
        uTimeMultiplierX: new THREE.Uniform(1.02),
        uTimeMultiplierY: new THREE.Uniform(2.97),
        uR_Multiplier: new THREE.Uniform(-0.225),
        uR_Offset: new THREE.Uniform(0.52),
        uG_Multiplier: new THREE.Uniform(-0.079),
        uG_Offset: new THREE.Uniform(-0.25),
        uB_Multiplier: new THREE.Uniform(-0.03),
        uB_Offset: new THREE.Uniform(0.13)
    },

    // MeshPhysicalMaterial
    // metalness: 1,
    roughness: 0,
    // color: '#ffffff',
    // transmission: 0.5,
    // ior: 1.132,
    // thickness: 6.518,
    // transparent: true,
    // wireframe: true


})

gui.add(testShaderMaterial.uniforms.uAmount, 'value').min(0).max(200).step(1).name('uAmount')
gui.add(testShaderMaterial.uniforms.uScale, 'value').min(-10).max(20).step(0.01).name('uScale')
gui.add(testShaderMaterial.uniforms.uDivider1, 'value').min(-10).max(10).step(0.01).name('uDivider1')
gui.add(testShaderMaterial.uniforms.uMultiplierX, 'value').min(-10).max(10).step(0.01).name('uMultiplierX')
gui.add(testShaderMaterial.uniforms.uMultiplierY, 'value').min(-10).max(10).step(0.01).name('uMultiplierY')
gui.add(testShaderMaterial.uniforms.uTimeMultiplierX, 'value').min(-10).max(10).step(0.01).name('uTimeMultiplierX')
gui.add(testShaderMaterial.uniforms.uTimeMultiplierY, 'value').min(-10).max(10).step(0.01).name('uTimeMultiplierY')
gui.add(testShaderMaterial.uniforms.uR_Multiplier, 'value').min(-1).max(5).step(0.001).name('uR_Multiplier')
gui.add(testShaderMaterial.uniforms.uR_Offset, 'value').min(-2).max(10).step(0.01).name('uR_Offset')
gui.add(testShaderMaterial.uniforms.uG_Multiplier, 'value').min(-1).max(5).step(0.001).name('uG_Multiplier')
gui.add(testShaderMaterial.uniforms.uG_Offset, 'value').min(-2).max(10).step(0.01).name('uG_Offset')
gui.add(testShaderMaterial.uniforms.uB_Multiplier, 'value').min(-1).max(5).step(0.001).name('uB_Multiplier')
gui.add(testShaderMaterial.uniforms.uB_Offset, 'value').min(-2).max(10).step(0.01).name('uB_Offset')


// CAMERA
// ----------------------------------------------------------------------------------
//
// Base camera
const camera = new THREE.PerspectiveCamera(80, sizes.width / sizes.height, 0.1, 100)
camera.position.set(4, 1, - 4)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true





// RENDERER
// ----------------------------------------------------------------------------------
//
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 3
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))



// POST-PROCESSING
// ----------------------------------------------------------------------------------
//
const renderTarget = new THREE.WebGLRenderTarget(
    800,
    600,
    {
        samples: 2
    }
)

// Effect composer
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
effectComposer.setSize(sizes.width, sizes.height)

// Render pass
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)

// Gamma correction pass
const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader)
effectComposer.addPass(gammaCorrectionPass)

// Dot screen pass
const dotScreenPass = new DotScreenPass()
// dotScreenPass.enabled = false
effectComposer.addPass(dotScreenPass)


// Antialias pass
if (renderer.getPixelRatio() === 1 && !renderer.capabilities.isWebGL2) {
    const smaaPass = new SMAAPass()
    effectComposer.addPass(smaaPass)

    console.log('Using SMAA')
}

// Unreal Bloom pass
const unrealBloomPass = new UnrealBloomPass()
unrealBloomPass.enabled = true
// unrealBloomPass.enabled = false
effectComposer.addPass(unrealBloomPass)

unrealBloomPass.strength = 0.97
unrealBloomPass.radius = 0.405
unrealBloomPass.threshold = 0.179

gui.add(unrealBloomPass, 'enabled')
gui.add(unrealBloomPass, 'strength').min(0).max(2).step(0.001)
gui.add(unrealBloomPass, 'radius').min(0).max(2).step(0.001)
gui.add(unrealBloomPass, 'threshold').min(0).max(1).step(0.001)




// ANIMATE
// ----------------------------------------------------------------------------------
//

const clock = new THREE.Clock()

const tick = () => {

    // Stats Code
    stats.begin()

    const elapsedTime = clock.getElapsedTime()

    // Update Materials
    // // Wobble
    // uniforms.uTime.value = elapsedTime
    testShaderMaterial.uniforms.uTime.value = elapsedTime

    // Update controls
    controls.update()

    // Render
    // renderer.render(scene, camera)

    // Use when Effects Composer is active
    effectComposer.render()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    // Stats Code
    stats.end()
}

tick()